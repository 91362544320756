import React from 'react';
import ReactDOM from 'react-dom';
import '@webcomponents/webcomponentsjs';
import ActionsLogApp from './packages/ActionLogs/App';
import { MainWrapper, ThemeWrapper } from '@flixbus/honeycomb-react';
import { msalInstance, withMsalAuth } from './shared/auth';
import { MsalProvider } from '@azure/msal-react';
import { StyleSheetManager } from 'styled-components';
import { TranslateProvider } from './shared/system/translate';
import { InitAppContext } from './shared/system/initAppContext/initAppContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import NotificationsLogApp from './packages/NotificationLogs/App';
import customStyles from './styles';
import './index.scss';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
});

const Protected = (props) => {
    const { token, initialLanguage, component: AppComponent } = props;

    if (token) {
        return (
            <TranslateProvider initialLanguage={initialLanguage}>
                <QueryClientProvider client={queryClient}>
                    <AppComponent {...props} />
                    <ReactQueryDevtools />
                </QueryClientProvider>
            </TranslateProvider>
        );
    } else {
        return withMsalAuth(() => (
            <TranslateProvider initialLanguage={initialLanguage}>
                <QueryClientProvider client={queryClient}>
                    <AppComponent {...props} />
                    <ReactQueryDevtools />
                </QueryClientProvider>
            </TranslateProvider>
        ))();
    }
};

class ReactWebComponent extends HTMLElement {
    constructor(AppComponent) {
        super();
        this.styleHost = document.createElement('div');
        this.mountPoint = document.createElement('div');
        this.attachShadow({ mode: 'open' });
        this.AppComponent = AppComponent;

        // copy all honeycomb styles from html header and paste to shadow root
        this.observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach((mutation) => {
                if (mutation.addedNodes && mutation.addedNodes.length > 0) {
                    mutation.addedNodes.forEach((node) => {
                        if (
                            node instanceof HTMLStyleElement &&
                            node.classList.contains('honeycomb-react-css-tag')
                        ) {
                            this.shadowRoot.appendChild(node.cloneNode(true));
                        }
                    });
                }
            });
        });
    }

    connectedCallback() {
        this.shadowRoot?.appendChild(this.styleHost);
        this.shadowRoot?.appendChild(this.mountPoint);
        const props = JSON.parse(this.getAttribute('props'));

        const styleTag = document.createElement('style');
        styleTag.textContent = customStyles;
        this.shadowRoot.appendChild(styleTag);

        ReactDOM.render(
            <StyleSheetManager target={this.styleHost}>
                <MsalProvider instance={msalInstance}>
                    <MainWrapper>
                        <ThemeWrapper>
                            <InitAppContext.Provider value={props}>
                                <Protected {...props} component={this.AppComponent} />
                            </InitAppContext.Provider>
                        </ThemeWrapper>
                    </MainWrapper>
                </MsalProvider>
            </StyleSheetManager>,
            this.mountPoint
        );

        // copy all honeycomb styles from html header and paste to shadow root
        const existingStyles = document.head.querySelectorAll('style.honeycomb-react-css-tag');
        existingStyles.forEach((style) => {
            this.shadowRoot.appendChild(style.cloneNode(true));
        });

        this.observer.observe(document.head, { childList: true });
    }

    disconnectedCallback() {
        if (!this.isConnected) {
            this.shadowRoot?.removeChild(this.mountPoint);
            this.shadowRoot?.removeChild(this.styleHost);
            this.observer.disconnect();
        }
    }
}

class ActionLogWebComponent extends ReactWebComponent {
    constructor() {
        super(ActionsLogApp);
    }
}
class NotificationLogWebComponent extends ReactWebComponent {
    constructor() {
        super(NotificationsLogApp);
    }
}

customElements.define('action-logger', ActionLogWebComponent);
customElements.define('notification-log', NotificationLogWebComponent);
