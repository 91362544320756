import { PublicClientApplication } from '@azure/msal-browser';

/**
 * config for auth microsoft
 * @constant
 * @name msalConfig
 */

export const msalConfig = {
    auth: {
        clientId: 'e14ba164-a4e4-405e-8fe8-c8fb0cc702d0',
        authority: 'https://login.microsoftonline.com/d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
    system: {
        allowRedirectInIframe: true,
    },
};

export const scopes = [
    'https://einfachbusfahren.onmicrosoft.com/e14ba164-a4e4-405e-8fe8-c8fb0cc702d0/API.ReadWrite',
];

export const msalPopupRequest = {
    scopes,
    redirectUri: window.location.origin,
};

export const msalSilentRequest = {
    scopes,
};

export const msalInstance = new PublicClientApplication(msalConfig);
