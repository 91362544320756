import React, { useContext } from 'react';
import { formatDateWithLocalOffset } from '../../../../shared/util/date';
import { TranslateContext } from '../../../../shared/system/translate';

const StationRelocation = ({
    item: {
        details,
        details: { station },
    },
}) => {
    const { translate } = useContext(TranslateContext);

    return (
        <p>
            <strong>
                ({station.code})-{station.name}
            </strong>{' '}
            {translate('station_temporarily_relocated')} <strong>{details.newAddress}</strong>{' '}
            {translate('since')} {formatDateWithLocalOffset(details.activeSince)}{' '}
            {translate('till')} {formatDateWithLocalOffset(details.activeTill)}. <br />
            {translate('old_address')} - <strong>{details.oldAddress}.</strong> <br />{' '}
            <strong>{translate('action-log.description')}:</strong> {details.description}
        </p>
    );
};

export default StationRelocation;
