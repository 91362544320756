import {
    Flag,
    Header,
    HeaderBrand,
    HeaderWidgets,
    LanguageSwitcher,
    LanguageSwitcherPopup,
    LanguageSwitcherPopupItem,
    LanguageSwitcherPopupRegion,
    LanguageSwitcherToggle,
} from '@flixbus/honeycomb-react';
import React, { useContext } from 'react';
import { TranslateContext } from '../../system/translate';

const Layout = ({ children }) => {
    const { langsList, language, setLanguage, translate } = useContext(TranslateContext);
    const activeLanguage = langsList[language];

    const handleLangItemClick = (code) => {
        setLanguage(code);
    };

    return (
        <>
            <Header>
                <HeaderBrand
                    alt="Action Logger"
                    href="/"
                    src="https://cdn-cf.cms.flixbus.com/drupal-assets/logos/flixbus.png"
                />
                <HeaderWidgets noResponsiveRendering>
                    <LanguageSwitcher>
                        <LanguageSwitcherToggle
                            flag={
                                <Flag
                                    countryCode={activeLanguage.flagCode}
                                    countryName={activeLanguage.countryName}
                                />
                            }
                        >
                            {activeLanguage.title}
                        </LanguageSwitcherToggle>

                        <LanguageSwitcherPopup
                            aria-label="Language selector."
                            closeLabel="Close language selector."
                        >
                            <LanguageSwitcherPopupRegion title={translate('select_language')}>
                                {Object.keys(langsList).map((key) => {
                                    const { flagCode, countryName, code, title } = langsList[key];

                                    return (
                                        <LanguageSwitcherPopupItem
                                            active={activeLanguage.code === code}
                                            key={code}
                                            onClick={() => handleLangItemClick(code)}
                                            flag={
                                                <Flag
                                                    countryCode={flagCode}
                                                    countryName={countryName}
                                                />
                                            }
                                            lang={code}
                                        >
                                            {title}
                                        </LanguageSwitcherPopupItem>
                                    );
                                })}
                            </LanguageSwitcherPopupRegion>
                        </LanguageSwitcherPopup>
                    </LanguageSwitcher>
                </HeaderWidgets>
            </Header>
            {children}
        </>
    );
};

export default Layout;
