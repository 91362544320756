import React, { useContext, useState } from 'react';
import { Button, DataTableRow, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconNewsletterSolid, IconPhoneTablet } from '@flixbus/honeycomb-icons-react';
import { getUtcOffsetText, parseDate } from '../../../shared/util/date';
import dayjs from '../../../shared/system/day';
import { TranslateContext } from '../../../shared/system/translate';

const NotificationsLogItem = ({ notification }) => {
    const [formatted, setFormatted] = useState(false);
    const { translate } = useContext(TranslateContext);

    const hasHTML = () => {
        return notification.message.search(/<\/?\w*\/?>/gi) !== -1;
    };

    const toggleFormatted = () => {
        setFormatted(!formatted);
    };

    const sanitizeMessage = () => {
        const test = notification.message;
        return test.replace(/(<script.*?>.*<\/script>)|(<style.*?>.*<\/style>)/gi, '');
    };

    return (
        <DataTableRow>
            <th>
                {notification.recipient}
                {notification.isPush && (
                    <Tooltip
                        id="push-id"
                        content="Push"
                        openOnHover
                        smartPosition
                        size="content-fit"
                    >
                        <Icon appearance="primary" InlineIcon={IconPhoneTablet} title="Push" />
                    </Tooltip>
                )}
            </th>
            <td style={{ minWidth: '220px' }}>
                {parseDate(notification.sentAt, 'DD.MM.YYYY, HH:mm') + getUtcOffsetText(dayjs())}
            </td>
            <td style={{ maxWidth: '800px' }}>
                {hasHTML() && (
                    <Tooltip
                        content={translate('notifications-log.format-email')}
                        id="format-email"
                        smartPosition
                        size="content-fit"
                        openOnHover
                    >
                        <Button appearance="link" onClick={toggleFormatted}>
                            <Icon appearance="primary" InlineIcon={IconNewsletterSolid} />
                        </Button>
                    </Tooltip>
                )}
                {formatted ? (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: sanitizeMessage(),
                        }}
                    />
                ) : (
                    notification.message.replace(/<\/?\w*\/?>|&nbsp;/gi, ' ').replace(/\s+/, ' ')
                )}
            </td>
            <td>{notification.sentBy}</td>
        </DataTableRow>
    );
};

export default NotificationsLogItem;
