import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../../shared/system/translate';

const RideCancelled = ({ item }) => {
    const { translate } = useContext(TranslateContext);
    const { reasonText, cancelled } = item?.details;

    const isCancelledText = cancelled ? translate('cancelled') : translate('uncancelled');

    return (
        <>
            {translate('ride-was')} <strong>{isCancelledText}</strong>.{' '}
            {reasonText && `${translate('reason')}: ${reasonText}`}
        </>
    );
};

RideCancelled.propTypes = {
    item: PropTypes.object.isRequired,
};

export default RideCancelled;
