import React, { useContext, useEffect } from 'react';
import { InitAppContext } from '../../../shared/system/initAppContext/initAppContext';
import useNotificationLog from '../hooks/useNotificationLog';
import { Button, Grid, GridCol, Spinner } from '@flixbus/honeycomb-react';
import LogsTableSkeleton from '../../ActionLogs/Logs/LogsTable/LogsTableSkeleton';
import { ButtonContainer } from '../../ActionLogs/Logs/Logs/Logs.styled';
import { TranslateContext } from '../../../shared/system/translate';
import NotificationsTable from './NotificationsTable';

import { NotificationsContainer } from './Notifications.styled';

const Notifications = () => {
    const { translate } = useContext(TranslateContext);
    const { rideId: providedRideId } = useContext(InitAppContext);

    const {
        notifications,
        isFetching,
        fetchNextNotifications,
        isFetchingNextPage,
        hasNextPage,
        refetch,
        isFetched,
    } = useNotificationLog({
        labels: [{ name: 'rideId', value: providedRideId }],
    });

    useEffect(() => {
        refetch();
    }, []);

    return (
        <NotificationsContainer>
            <Grid>
                <GridCol size={12}>
                    {isFetching && <LogsTableSkeleton />}
                    {isFetched && !notifications.length && <p>{translate('no-logs-found')}</p>}
                    {notifications.length ? (
                        <NotificationsTable notifications={notifications} />
                    ) : null}
                    {hasNextPage && (
                        <ButtonContainer>
                            <Button
                                appearance="tertiary"
                                onClick={fetchNextNotifications}
                                disabled={isFetchingNextPage}
                            >
                                {isFetchingNextPage ? (
                                    <Spinner size="sm" />
                                ) : (
                                    translate('load_more')
                                )}
                            </Button>
                        </ButtonContainer>
                    )}
                </GridCol>
            </Grid>
        </NotificationsContainer>
    );
};

export default Notifications;
