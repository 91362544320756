import axios from "axios";

export const setTokenInterceptor = (token) => {
    axios.interceptors.request.use(
        async (config) => {
            const requestConfig = { headers: {}, ...config };

            if (token) {
                requestConfig.headers.Authorization = `Bearer ${token}`;
            }
            return requestConfig;
        },
        (error) => Promise.reject(error)
    );
}