import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../../shared/system/translate';

export default function BusSegmentStats({ item }) {
    const { translate } = useContext(TranslateContext);
    const { name, id, code } = item.details.station;
    const { type } = item;

    return (
        <div>
            {translate('segment-stats-' + type.toLowerCase())}: ({code})-<strong>{name}</strong>.{' '}
            {translate('station-id')} <strong>{id}</strong>
        </div>
    );
}

BusSegmentStats.propTypes = {
    item: PropTypes.object.isRequired,
};
