import React, { useContext } from 'react';
import { TranslateContext } from '../../../../shared/system/translate';

const DriverActions = ({ item }) => {
    const { translate } = useContext(TranslateContext);
    const { fullName, partnershipRole, fromStation, toStation, partnershipPhone } = item?.details;
    const { flixDriverVersionName, phoneNumber } = item.details.driverUser;
    const isDriverAddedType = item.type.toLowerCase() === 'driver_added';

    const displayPhone = phoneNumber
        ? `(${translate('phone')} ${phoneNumber})`
        : partnershipPhone
        ? `(${translate('phone')} ${partnershipPhone})`
        : '';

    return (
        <div>
            {isDriverAddedType ? translate('registered_as') : translate('removed_as')}{' '}
            {partnershipRole}: <strong>{fullName}</strong> {displayPhone}
            <br />
            {translate('from_station')}{' '}
            <strong>
                {' '}
                {fromStation.name} ({fromStation.code})
            </strong>{' '}
            {translate('to')}{' '}
            <strong>
                {toStation.name} ({toStation.code})
            </strong>
            <br />
            {translate('driver_app_version')}: <strong>{flixDriverVersionName}</strong>
        </div>
    );
};

export default DriverActions;
