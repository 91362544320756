import React, { useState } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import enLocale from 'dayjs/locale/en';
import deLocale from 'dayjs/locale/de';
import trLocale from 'dayjs/locale/tr';
import hiLocale from 'dayjs/locale/hi';
import esLocale from 'dayjs/locale/es';

import TranslateContext from './TranslateContext';
import langsList from '../langsList';
import localStorage from '../localStorage';

import en from '../vocabularies/en.json';
import de from '../vocabularies/de.json';
import tr from '../vocabularies/tr.json';
import es from '../vocabularies/es.json';
import hi from '../vocabularies/hi.json';
import cl from '../vocabularies/cl.json';

const DEFAULT_LNG = 'en';
const DAYJS_LOCALES = {
    en: enLocale,
    de: deLocale,
    tr: trLocale,
    hi: hiLocale,
    es: esLocale,
    cl: esLocale,
};
const storage = localStorage;

export default function TranslateProvider({ children, initialLanguage }) {
    const hostAppLanguage = langsList[initialLanguage]?.code || DEFAULT_LNG;
    const [translations] = useState({
        en,
        de,
        tr,
        es,
        hi,
        cl,
    });

    const [language, setLanguage] = useState(
        hostAppLanguage || storage.getItem(storage.LANGUAGE) || DEFAULT_LNG
    );

    const onLanguageSet = (value) => {
        storage.setItem(storage.LANGUAGE, value);
        setLanguage(value);
        dayjs.locale(DAYJS_LOCALES[language]);
    };

    const translate = (key, param) => {
        const regExp = /{(.*?)}/gi;
        let message = translations[language][key];

        // replace the translation with the passed param
        if (param && message) {
            if (typeof param === 'object') {
                message = message.replace(regExp, (match, substr) => param[substr] || match);
            } else {
                message = message.replace(regExp, param);
            }
        }

        return message || `<${key.match(/\.?(\w*)$/i)[1]}>`;
    };

    const providerValue = {
        langsList,
        language,
        setLanguage: onLanguageSet,
        translate,
    };

    return <TranslateContext.Provider value={providerValue}>{children}</TranslateContext.Provider>;
}

TranslateProvider.propTypes = {
    children: PropTypes.node.isRequired,
    initialLanguage: PropTypes.string,
};
