import dayjs from '../system/day';

const DEFAULT_FORMAT = 'MMMM Do YYYY, HH:mm:ss';

export const getUtcOffset = (time) => {
    const timeSet = dayjs(time);
    return timeSet.utcOffset() / 60;
};

export const getUtcOffsetText = (time) => {
    const utc = getUtcOffset(time);
    return utc !== null ? ` (UTC ${utc >= 0 ? '+' : ''}${utc})` : `${utc}`;
};

// expected argument example: Europe/Paris
export const getUtcOffsetString = (timezone) => {
    return `UTC ${dayjs()
        .tz(timezone)
        .format('Z')
        .replace(':00', '')
        .replace(/^(\+|-)0/, '$1')}`;
};

export const parseDate = (value, format = DEFAULT_FORMAT, preserveTZ = false) => {
    let parsedDate;

    if (preserveTZ) {
        parsedDate = dayjs(value);
    } else {
        parsedDate = dayjs.utc(value).local();
    }

    return parsedDate.isValid() ? parsedDate.format(format) : value;
};

export const getTimeFromUTCDate = (date, timezone) => {
    return date ? dayjs.utc(date).tz(timezone).format('HH:mm') : '';
};

//expected format: ISO 8601. 2024-06-06T00:02:00Z
export const formatDateWithLocalOffset = (dateString) => {
    const utcDate = dayjs.utc(dateString).local();

    const format = 'MM.DD.YYYY, HH:mm';
    const formattedDate = utcDate.format(format);
    const localOffset = utcDate.format('Z').replace(':00', '');

    return `${formattedDate} (UTC${localOffset})`;
};
