import React, { useContext } from 'react';
import dayjs from '../../../../shared/system/day';
import { Connection, ConnectionStop, ConnectionTime, Time } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../../../shared/system/translate';

const formatTime = (data) => {
    const { timezone, departure = null, arrival = null } = data;

    const formatWithUTCOffset = (time) => {
        const timeObject = dayjs.unix(time).tz(timezone);
        const offset = timeObject.utcOffset() / 60;
        const formattedTime = timeObject.format('DD.MM.YYYY, HH:mm');
        const sign = offset >= 0 ? '+' : '';

        return `${formattedTime} (UTC ${sign}${offset})`;
    };

    let formattedDeparture = departure ? formatWithUTCOffset(departure) : undefined;
    let formattedArrival = arrival ? formatWithUTCOffset(arrival) : undefined;

    return {
        departure: formattedDeparture,
        arrival: formattedArrival,
    };
};

const getRouteConnectionStop = (route1, route2, isOldRoute) => {
    return route1.map((stop) => {
        let modifier = '';
        let isArrivalTimeModified = false;
        let isDepartureTimeModified = false;

        const correspondingStop = route2.find(
            (otherStop) => otherStop.station.name === stop.station.name
        );

        // check if station is added or removed
        if (isOldRoute && !correspondingStop) {
            modifier = '--removed';
        } else if (!isOldRoute && !correspondingStop) {
            modifier = '--added';
        }

        // check if time is changed for same station
        if (!isOldRoute && correspondingStop) {
            isArrivalTimeModified = stop.time?.arrival !== correspondingStop.time.arrival;
            isDepartureTimeModified = stop.time?.departure !== correspondingStop.time.departure;
        }

        const { arrival, departure } = formatTime(stop.time);

        return (
            <ConnectionStop
                key={stop.station.name}
                extraClasses={`al__connection-stop${modifier}`}
                station={`(${stop.station.code})-${stop.station.name}`}
                time={
                    <ConnectionTime
                        time={
                            <Time style={isArrivalTimeModified ? { color: '#3AB4F2' } : {}}>
                                {arrival || ''}
                            </Time>
                        }
                        secondTime={
                            <Time style={isDepartureTimeModified ? { color: '#3AB4F2' } : {}}>
                                {departure || ''}
                            </Time>
                        }
                    />
                }
            />
        );
    });
};

export default function RideConceptChange({ item }) {
    const { translate } = useContext(TranslateContext);

    const newRoute = item?.details?.newConcept?.route;
    const oldRoute = item?.details?.oldConcept?.route;

    return (
        <>
            <p style={{ margin: 0 }}>{translate('ride_concept_changed')}</p>
            <div style={{ display: 'flex' }}>
                <div style={{ paddingRight: 30, paddingBottom: 20 }}>
                    <p>
                        <strong>{translate('old-concept')}:</strong>
                    </p>
                    <Connection>{getRouteConnectionStop(oldRoute, newRoute, true)}</Connection>
                </div>
                <div style={{ paddingBottom: 20 }}>
                    <p>
                        <strong>{translate('new-concept')}:</strong>
                    </p>
                    <Connection>{getRouteConnectionStop(newRoute, oldRoute, false)}</Connection>
                </div>
            </div>
        </>
    );
}
