import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../../shared/system/translate';
import { BACKEND_HOST } from '../../../../shared/api/clientSetup';
import { Link } from '@flixbus/honeycomb-react';

export default function Rebookr2Rebooked({ item }) {
    const { newOrderId, sourceOrderId } = item.details;
    const { translate } = useContext(TranslateContext);

    return (
        <>
            {translate('order')}&nbsp;
            <Link
                href={`${BACKEND_HOST}/reports/orders/${sourceOrderId}/view`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {sourceOrderId}
            </Link>
            &nbsp;
            {translate('was_rebooked_to_new_order')}:&nbsp;
            <Link
                href={`${BACKEND_HOST}/reports/orders/${newOrderId}/view`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {newOrderId}
            </Link>
        </>
    );
}

Rebookr2Rebooked.propTypes = {
    item: PropTypes.object.isRequired,
};
