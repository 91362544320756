import { Button, Grid, GridCol, Input, Spinner } from '@flixbus/honeycomb-react';
import { LogsTable } from '../LogsTable';
import { useCallback, useContext, useMemo, useState } from 'react';
import { TranslateContext } from '../../../../shared/system/translate';
import { InitAppContext } from '../../../../shared/system/initAppContext/initAppContext';
import { useLogs } from '../../hooks';
import Filters from '../LogsFilters';
import LogsTableSkeleton from '../LogsTable/LogsTableSkeleton';
import {
    ButtonContainer,
    LogsContainer,
    SearchFormContainer,
    IllustrationContainer,
} from './Logs.styled';
import { Icon, IconMagnifier } from '@flixbus/honeycomb-icons-react';

const LogsComponent = () => {
    const { translate } = useContext(TranslateContext);
    const { isStandaloneApp, rideId: providedRideId } = useContext(InitAppContext);

    const [activeRideId, setActiveRideId] = useState(providedRideId);

    const { logs, isFetching, fetchNextLogs, isFetchingNextPage, hasNextPage, refetch, isFetched } =
        useLogs({
            labels: [{ name: 'rideId', value: activeRideId }],
        });

    const [inputRideId, setInputRideId] = useState('');

    const [filters, setFilters] = useState([]);

    const handleSubmitRideId = () => {
        setActiveRideId(inputRideId);
        refetch();
    };

    const filteredLogs = useMemo(() => {
        return logs.filter((log) => filters.includes(log.type.toLowerCase()));
    }, [logs, filters]);

    const handleFiltersChange = useCallback((filters) => {
        const flatFilters = Object.values(filters).flat();
        setFilters(flatFilters);
    }, []);

    const showSkeleton = isFetching && !isStandaloneApp && !isFetchingNextPage;
    const showNoLogsIllustration =
        !isFetched && !logs.length && !filteredLogs.length && isStandaloneApp;

    return (
        <LogsContainer fullSpace={!isStandaloneApp}>
            {isStandaloneApp && (
                <SearchFormContainer>
                    <Input
                        id="ride_id"
                        label="Ride id"
                        placeholder="Search by ride id"
                        value={inputRideId}
                        onChange={(e) => setInputRideId(e.target.value)}
                        iconLeft={<Icon InlineIcon={IconMagnifier} />}
                    />
                    <Button
                        appearance="primary"
                        style={{ marginTop: '10px' }}
                        onClick={handleSubmitRideId}
                    >
                        {isFetching && !isFetchingNextPage ? <Spinner size="sm" /> : 'Submit'}
                    </Button>
                </SearchFormContainer>
            )}
            <Grid>
                <GridCol size={2}>
                    <Filters logs={logs} onFilterChange={handleFiltersChange} />
                </GridCol>
                <GridCol size={10}>
                    {showSkeleton && <LogsTableSkeleton />}
                    {showNoLogsIllustration && (
                        <IllustrationContainer>
                            <flix-illustration name="search-file"></flix-illustration>
                        </IllustrationContainer>
                    )}
                    {isFetched && !logs.length && <p>{translate('no-logs-found')}</p>}
                    {logs.length ? <LogsTable logs={filters.length ? filteredLogs : logs} /> : null}
                    {hasNextPage && (
                        <ButtonContainer>
                            <Button
                                appearance="tertiary"
                                onClick={fetchNextLogs}
                                disabled={isFetchingNextPage}
                            >
                                {isFetchingNextPage ? (
                                    <Spinner size="sm" />
                                ) : (
                                    translate('load_more')
                                )}
                            </Button>
                        </ButtonContainer>
                    )}
                </GridCol>
            </Grid>
        </LogsContainer>
    );
};

export default LogsComponent;
