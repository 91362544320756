import React, { useContext, useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Grid, GridCol, Button, Heading, Header, HeaderBrand } from '@flixbus/honeycomb-react';
import { Icon, IconSecuritySolid } from '@flixbus/honeycomb-icons-react';
import { EventType } from '@azure/msal-browser';
import { NotificationContext } from '../components/Notification';
import { msalPopupRequest } from './msalConfig';
import { msalInstance } from './index';

export default function withMsalAuth(Component) {
    return function WithMsalAuth(props) {
        const notify = useContext(NotificationContext);
        /**
         * @todo
         * processed login flow events
         * send exceptions to monitoring
         * notify users if errors
         * */
        /**
         * @todo
         * place translations handler to header
         * use translations for text
         * */
        useEffect(() => {
            const callback = msalInstance.addEventCallback((message) => {
                if (
                    message.eventType === EventType.LOGIN_FAILURE ||
                    message.eventType === EventType.SSO_SILENT_FAILURE ||
                    message.eventType === EventType.ACQUIRE_TOKEN_FAILURE
                ) {
                    if (
                        message.error
                            .toString()
                            .search(
                                /popup_window_error|empty_window_error|monitor_window_timeout/
                            ) !== -1
                    ) {
                        notify({
                            type: 'danger',
                            text: 'Login failed. Please check if popups are blocked in a browser. Change settings and try again.',
                        });
                    }
                }
            });
            return () => {
                msalInstance.removeEventCallback(callback);
            };
        }, [notify]);

        function login() {
            msalInstance.loginPopup({ ...msalPopupRequest }).catch((e) => {
                console.log('login popup', e);
                notify({ type: 'danger', text: `Auth error ${e}` });
            });
        }

        // TODO: update layout when honeycomb will be integrated
        return (
            <>
                <AuthenticatedTemplate>
                    <Component {...props} />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Header>
                        <HeaderBrand
                            alt="Action Logger"
                            href="/"
                            src="https://cdn-cf.cms.flixbus.com/drupal-assets/logos/flixbus.png"
                        />
                    </Header>
                    <div className="al-login-box" style={{ textAlign: 'center' }}>
                        <Grid justify="center">
                            <GridCol size={3}>
                                <Heading size={3}>
                                    <Icon InlineIcon={IconSecuritySolid} size={6} />
                                </Heading>
                                <Heading size={4}>Login to Action Logger</Heading>
                                <Button onClick={login} aria-label="Login" appearance="primary">
                                    Login
                                </Button>
                            </GridCol>
                        </Grid>
                    </div>
                </UnauthenticatedTemplate>
            </>
        );
    };
}
