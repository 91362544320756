import React from 'react';
import { Tag } from '@flixbus/honeycomb-react';
import classNames from 'classnames';
import { Icon } from '@flixbus/honeycomb-icons-react';

export default function RideTag(props) {
    const { action, name, color, InlineIcon, small } = props;
    const borderColor = {
        borderColor: color === 'white' ? 'black' : color,
    };
    const bgColor = {
        backgroundColor: color === 'white' ? 'black' : color,
    };
    const tagCss = classNames('ride-tag', { 'ride-tag--small': small });

    return (
        <Tag extraClasses={tagCss} style={borderColor} small={!!small} display="outlined">
            <span className="ride-tag__dot" style={bgColor} />
            {name}
            {action && (
                <button className="overlay-mimic-btn" onClick={action}>
                    &nbsp;
                </button>
            )}
            {InlineIcon && <Icon InlineIcon={InlineIcon} />}
        </Tag>
    );
}
