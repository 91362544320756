import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../../shared/system/translate';

export default function StationOpened({ item }) {
    const { translate } = useContext(TranslateContext);
    const { name, id, code } = item.details.station;

    return (
        <div>
            {translate('station_open')}: ({code})-<strong>{name}</strong>. {translate('station-id')}{' '}
            <strong>{id}</strong>
        </div>
    );
}

StationOpened.propTypes = {
    item: PropTypes.object.isRequired,
};
