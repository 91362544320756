import RideStatusChanged from '../renderers/RideStatusChanged';
import RideTagAssignment from '../renderers/RideTagAssignment';
import GhostOrderDetected from '../renderers/GhostOrderDetected';
import RideConceptChange from '../renderers/RideConceptChange';
import PaxStatus from '../renderers/PaxStatus';
import Rebookr2Rebooked from '../renderers/Rebookr2Rebooked';
import DelayGoodies from '../renderers/DelayGoodies';
import DelayAdded from '../renderers/DelayAdded';
import StationOpened from '../renderers/StationOpened';
import StationClosed from '../renderers/StationClosed';
import StationCancelled from '../renderers/StationCancelled';
import StationRelocation from '../renderers/StationRelocation';
import DriverActions from '../renderers/DriverActions';
import RideTagAssignmentV2 from '../renderers/RideTagAssignmentV2';
import RideCancelled from '../renderers/RideCancelled';
import BusSegmentStats from "../renderers/BusSegmentStats";

export const RendererMap = {
    tag_added: RideTagAssignment,
    tag_removed: RideTagAssignment,
    ride_status_changed: RideStatusChanged,
    ghost_order: GhostOrderDetected,
    ride_concept_change: RideConceptChange,
    pax_checked: PaxStatus,
    pax_unchecked: PaxStatus,
    pax_absent: PaxStatus,
    rebooked: Rebookr2Rebooked,
    delay_goodies: DelayGoodies,
    automated_delay: DelayAdded,
    station_open: StationOpened,
    station_closed: StationClosed,
    station_cancel: StationCancelled,
    manual_delay: DelayAdded,
    station_relocation: StationRelocation,
    driver_added: DriverActions,
    driver_removed: DriverActions,
    tag_assignment: RideTagAssignmentV2,
    ride_cancellation: RideCancelled,
    bus_arrived: BusSegmentStats,
    bus_departed: BusSegmentStats
};

export const ServiceMap = {
    ims: 'action-log-item.ims',
    backend: 'action-log-item.monolith-backend',
    api: 'action-log-item.monolith-api',
    rebookr: 'action-log-item.rebookr',
    chancellor: 'action-log-item.chancellor',
    rideviewer: 'action-log-item.rideviewer',
    ridetracker: 'action-log-item.ridetracker',
    autocontrol: 'action-log-item.autocontrol',
    'autocontrol 3': 'action-log-item.autocontrol_3',
    'rebookr 2.0': 'action-log-item.rebookr2',
    driverapp: 'action-log-item.driver-app',
    had: 'action-log-item.had',
    megadrive: 'action-log-item.megadrive',
    eta: 'action-log-item.eta',
    tagsbank: 'action-log-item.tagsbank',
};
