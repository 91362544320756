import React, { useContext, useState, useEffect } from 'react';
import { DataTable, DataTableHead, DataTableSorter, Grid, GridCol } from '@flixbus/honeycomb-react';
import NotificationsLogItem from './NotificationsLogItem';
import { TranslateContext } from '../../../shared/system/translate';
import Filters from './components/Filters/Filters';

const NotificationsTable = ({ notifications }) => {
    const { translate } = useContext(TranslateContext);
    const [filteredNotifications, setFilteredNotifications] = useState(notifications);
    const [sortedNotifications, setSortedNotifications] = useState([]);
    const [currentSort, setCurrentSort] = useState('descending');

    useEffect(() => {
        sortNotifications(currentSort);
    }, [filteredNotifications, currentSort]);

    const sortNotifications = (sorting) => {
        setCurrentSort(sorting);

        const sorted = [...filteredNotifications].sort((a, b) => {
            if (sorting === 'ascending') {
                return a.sentAt.localeCompare(b.sentAt);
            } else if (sorting === 'descending') {
                return b.sentAt.localeCompare(a.sentAt);
            }
            return 0;
        });

        setSortedNotifications(sorted);
    };

    const filterNotifications = (filters) => {
        const filtered = notifications.filter((notification) => {
            return Object.entries(filters).every(([key, value]) => {
                if (value.size > 0) {
                    return Array.from(value).some(
                        (filter) => filter.toLowerCase() === notification[key]?.toLowerCase()
                    );
                }
                return true;
            });
        });

        setFilteredNotifications(filtered);
    };

    const headers = (
        <DataTableHead>
            <th>{translate('recipient')}</th>
            <DataTableSorter aria-label="Sort logs by date" onSortingChange={sortNotifications}>
                {translate('action-log.date')}
            </DataTableSorter>
            <th>{translate('message')}</th>
            <th>{translate('sentBy')}</th>
        </DataTableHead>
    );

    return (
        <Grid>
            <GridCol size={2}>
                <Filters notifications={notifications} onFiltersUpdate={filterNotifications} />
            </GridCol>
            <GridCol size={10}>
                <DataTable small headers={headers}>
                    {sortedNotifications.map((notification) => (
                        <NotificationsLogItem key={notification.id} notification={notification} />
                    ))}
                </DataTable>
            </GridCol>
        </Grid>
    );
};

export default NotificationsTable;
