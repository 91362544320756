import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../../../shared/system/translate';
import { BACKEND_HOST } from '../../../../shared/api/clientSetup';

const getReason = (item, translate) => {
    const { reason, station } = item.details;

    switch (reason) {
        case 'SCHEDULE_CHANGED':
            return (
                <span>
                    {translate('ghost-order-detected.because-schedule-changed')}:{' '}
                    <strong>
                        ({station.code})-{station.name}
                    </strong>
                </span>
            );
        case 'CONNECTION_MISSING':
            return (
                <span>
                    {translate('ghost-order-detected.because-connection')}{' '}
                    <strong>
                        ({station.code})-{station.name}
                    </strong>{' '}
                    {translate('ghost-order-detected.missing')}
                </span>
            );
        default:
            return (
                <span>
                    {translate('ghost-order-detected.because-station')}{' '}
                    <strong>
                        ({station.code})-{station.name}
                    </strong>{' '}
                    {translate('ghost-order-detected.because-station-removed')}
                </span>
            );
    }
};

export default function GhostOrderDetected({ item }) {
    const { translate } = useContext(TranslateContext);

    const reason = getReason(item, translate);

    return (
        <div>
            {translate('order')}&nbsp;
            <Link
                href={`${BACKEND_HOST}/reports/orders/${item.details.orderId}/view`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {item.details.orderId}
            </Link>
            &nbsp;
            {translate('ghost-order-detected.marked-ghost')}&nbsp;
            {reason}
        </div>
    );
}

GhostOrderDetected.propTypes = {
    item: PropTypes.object.isRequired,
};
