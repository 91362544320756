import React from 'react';
import { DataTable, DataTableHead, DataTableRow, Skeleton } from '@flixbus/honeycomb-react';

const LogsTableSkeleton = () => {
    const headers = (
        <DataTableHead>
            <th>
                <Skeleton width="md" height="sm" inline />
            </th>
            <td>
                <Skeleton width="md" height="sm" inline />
            </td>
            <td>
                <Skeleton width="md" height="sm" inline />
            </td>
            <td>
                <Skeleton width="md" height="sm" inline />
            </td>
        </DataTableHead>
    );

    const rows = Array(10)
        .fill(null)
        .map((el, idx) => (
            <DataTableRow key={idx}>
                <th>
                    <Skeleton width="md" height="sm" inline />
                </th>
                <td>
                    <Skeleton width="md" height="sm" inline />
                </td>
                <td>
                    <Skeleton width="md" height="sm" inline />
                </td>
                <td>
                    <Skeleton width="md" height="sm" inline />
                </td>
            </DataTableRow>
        ));

    return <DataTable headers={headers}>{rows}</DataTable>;
};

export default LogsTableSkeleton;
