import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../../shared/system/translate';

export default function RideStatusChanged({ item }) {
    const { translate } = useContext(TranslateContext);

    return (
        <div>
            {translate('ride-status-changed')} {translate('from')}{' '}
            <strong>{item.details.oldStatus}</strong> {translate('to')}{' '}
            <strong>{item.details.newStatus}</strong>
        </div>
    );
}

RideStatusChanged.propTypes = {
    item: PropTypes.object.isRequired,
};
