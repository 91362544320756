import { useInfiniteQuery } from '@tanstack/react-query';
import { useApi } from '../../../shared/api';

const PAGE_SIZE = 100;

const useLogs = (initialParams) => {
    const [, request] = useApi('post', '');

    const fetchLogs = async ({ pageParam = 0, size = PAGE_SIZE, params = {} }) => {
        const url = `/action-log?size=${size}&page=${pageParam}`;
        const response = await request({
            data: params,
            url: url,
        });
        return response.data;
    };

    const queryKey = ['logs', initialParams];

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
        isFetching,
        refetch,
        isFetched,
    } = useInfiniteQuery(
        queryKey,
        ({ pageParam = 0 }) => fetchLogs({ pageParam, params: initialParams }),
        {
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage || lastPage.length < PAGE_SIZE) return undefined;
                return pages.length;
            },
            enabled: !!initialParams.labels[0].value,
        }
    );

    return {
        logs: data?.pages?.flat() || [],
        fetchNextLogs: fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
        refetch,
        isFetching,
        isFetched,
    };
};

export default useLogs;
