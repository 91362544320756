import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../../shared/system/translate';

export default function StationCancelled({ item }) {
    const { translate } = useContext(TranslateContext);
    const { name, id, code } = item.details.station;

    return (
        <div>
            {translate('station_cancel')}: ({code})-<strong>{name}</strong>.{' '}
            {translate('station-id')} <strong>{id}</strong>
        </div>
    );
}

StationCancelled.propTypes = {
    item: PropTypes.object.isRequired,
};
