import axios from 'axios';
import { getToken } from '../auth';

export const BACKEND_HOST = 'https://backend.flixbus.com';

export const setMSALInterceptors = async () => {
    // if getAuthToken method provided set interceptors
    axios.interceptors.request.use(
        async (config) => {
            const requestConfig = { headers: {}, ...config };
            // get valid token before make request
            const token = await getToken().catch((e) => {
                throw new Error(`Authorization error: ${e}`);
            });

            if (token) {
                // set header with token
                requestConfig.headers.Authorization = `Bearer ${token}`;
            }
            return requestConfig;
        },
        (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
        (res) => res,
        async (error) => {
            if (
                error.message ||
                (error.response && error.response.status !== 401) ||
                (error.config && error.config.retry)
            ) {
                throw error;
            }
            // if response fail with authentication new token will be requested
            // and retry request
            await getToken().catch((e) => {
                throw new Error(`Authorization error: ${e}`);
            });
            const newRequest = {
                ...error.config,
                retry: true,
            };
            return axios(newRequest);
        }
    );
};
