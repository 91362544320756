import React from 'react';

const useGetNotificationFilters = (notifications) => {
    const filters = notifications.reduce(
        (accumulator, currentNotification) => {
            const channel = currentNotification.channel.toLowerCase();

            accumulator.channel[channel] = (accumulator.channel[channel] || 0) + 1;

            if (currentNotification.automatic) {
                const type = currentNotification.automatic.trim();
                accumulator.automatic[type] = (accumulator.automatic[type] || 0) + 1;
            }

            if (currentNotification.manual) {
                const sender = currentNotification.manual.trim();
                accumulator.manual[sender] = (accumulator.manual[sender] || 0) + 1;
            }

            return accumulator;
        },
        { automatic: {}, manual: {}, channel: {} }
    );

    return { filters };
};

export default useGetNotificationFilters;
