import { useInfiniteQuery } from '@tanstack/react-query';
import { useApi } from '../../../shared/api';

const PAGE_SIZE = 100;

const processNotificationSender = (notifications) => {
    return notifications.map((notification) => {
        const processedNotification = { ...notification };
        const sender = notification.sentBy;
        const isAutomatic = sender.toLowerCase().includes('customer notifier');

        if (isAutomatic) {
            processedNotification.automatic = sender.slice('Customer notifier:'.length).trim();
        } else {
            processedNotification.manual = sender;
        }

        return processedNotification;
    });
};

const useNotificationsLog = (initialParams) => {
    const [, request] = useApi('post', '');

    const fetchLogs = async ({ pageParam = 0, size = PAGE_SIZE, params = {} }) => {
        const url = `/notification-log?size=${size}&page=${pageParam}`;
        const response = await request({
            data: params,
            url: url,
        });
        return response.data;
    };

    const queryKey = ['notification-logs', initialParams];

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
        isFetching,
        refetch,
        isFetched,
    } = useInfiniteQuery(
        queryKey,
        ({ pageParam = 0 }) => fetchLogs({ pageParam, params: initialParams }),
        {
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage || lastPage.length < PAGE_SIZE) return undefined;
                return pages.length;
            },
            enabled: !!initialParams.labels[0].value,
        }
    );

    return {
        notifications: data?.pages ? processNotificationSender(data.pages.flat()) : [],
        fetchNextNotifications: fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
        refetch,
        isFetching,
        isFetched,
    };
};

export default useNotificationsLog;
