import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../../shared/system/translate';

const DelayGoodies = ({ item }) => {
    const { translate } = useContext(TranslateContext);

    return (
        <>
            {translate('delay_goodies_triggered')}
            <strong>{item.details.station.name}</strong>
        </>
    );
};

export default DelayGoodies;

DelayGoodies.propTypes = {
    item: PropTypes.object.isRequired,
};
