import { useEffect, useState } from 'react';
import { setTokenInterceptor } from '../../shared/api/setInterceptors';
import { setMSALInterceptors } from '../../shared/api/clientSetup';
import { Layout } from '../../shared/components/Layout';
import { Logs } from './Logs/Logs';
import { Spinner } from '@flixbus/honeycomb-react';

const App = ({ token, isStandaloneApp }) => {
    const [isInterceptorSet, setIsInterceptorSet] = useState(false);

    useEffect(() => {
        const setInterceptors = async () => {
            if (token) {
                await setTokenInterceptor(token);
            } else {
                await setMSALInterceptors();
            }
            setIsInterceptorSet(true);
        };
        setInterceptors();
    }, []);

    if (!isInterceptorSet) {
        return <Spinner />;
    }

    return !isStandaloneApp ? (
        <>
            <Logs />
        </>
    ) : (
        <Layout>
            <Logs />
        </Layout>
    );
};

export default App;
