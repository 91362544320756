import { InteractionRequiredAuthError } from '@azure/msal-browser';
import {
    msalInstance,
    msalPopupRequest,
    msalSilentRequest,
} from './msalConfig';
import withMsalAuth from './withMaslAuth';

export { withMsalAuth, msalInstance };

export function getToken() {
    return new Promise((resolve, reject) => {
        msalInstance
            .acquireTokenSilent({
                ...msalSilentRequest,
                account: msalInstance.getAllAccounts()[0],
            })
            .then((response) => {
                resolve(response.accessToken);
            })
            .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    return msalInstance
                        .acquireTokenPopup({
                            ...msalPopupRequest,
                            account: msalInstance.getAllAccounts()[0],
                        })
                        .then((response) => {
                            resolve(response.accessToken);
                        });
                }
                return e;
            })
            .catch((e) => reject(new Error(`Auth fail with error: ${e}`)));
    });
}

export function getUser() {
    const accounts = msalInstance.getAllAccounts();
    if (accounts[0]) {
        return {
            id: accounts[0].localAccountId,
            profile: accounts[0].idTokenClaims,
            userName: accounts[0].username,
        };
    }
    return null;
}
