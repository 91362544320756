import React, { useContext, useEffect, useState } from 'react';
import { Icon, IconPhoneSolid } from '@flixbus/honeycomb-icons-react';
import { DataTable, DataTableHead, DataTableRow, DataTableSorter } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../../../shared/system/translate';
import { parseDate, getUtcOffsetText } from '../../../../shared/util/date';
import { TableContainer } from './LogsTable.styled';
import { InitAppContext } from '../../../../shared/system/initAppContext/initAppContext';
import { ServiceMap, RendererMap } from '../Logs/Logs.namespace';
import dayjs from '../../../../shared/system/day';
import ErrorBoundary from '../../../../shared/system/ErrorBoundary';

const sortingOrders = {
    asc: 'ascending',
    desc: 'descending',
};

const LogsTable = ({ logs }) => {
    const { translate } = useContext(TranslateContext);
    const { isStandaloneApp } = useContext(InitAppContext);

    const [sortedLogs, setSortedLogs] = useState([]);
    const [sortingType, setSortingType] = useState('descending');

    const sortByDate = (sorting) => {
        const sorted = [...logs].sort((a, b) => {
            if (sorting === sortingOrders.asc) {
                return dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1;
            } else if (sorting === sortingOrders.desc) {
                return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1;
            } else {
                return 0;
            }
        });
        setSortedLogs(sorted);
        setSortingType(sorting);
    };

    useEffect(() => {
        sortByDate(sortingType);
    }, [logs, sortingType]);

    const headers = (
        <DataTableHead>
            <DataTableSorter
                onClick={() =>
                    sortingType === sortingOrders.asc
                        ? sortByDate(sortingOrders.desc)
                        : sortByDate(sortingOrders.asc)
                }
                order={sortingType}
                aria-label="Sort logs by date"
            >
                {translate('action-log.date')}
            </DataTableSorter>
            <th>{translate('action-log.description')}</th>
            <th>{translate('action-log.user')}</th>
            <th>{translate('action-log.app-version')}</th>
        </DataTableHead>
    );

    const rows = sortedLogs.map((item, idx) => {
        const { service, smiVersion, createdAt, type, user, clientInfo } = item;

        const getClientPhoneNumber = () => {
            const match = clientInfo ? clientInfo.match(/lin=(\+?\d+?),/) : null;
            return match !== null ? match[1] : null;
        };

        const getClientText = () => {
            const defaultText = translate('action-log-item.unknown');
            const normalizedClient = service?.toLowerCase();

            if (ServiceMap[normalizedClient]) {
                return translate(ServiceMap[normalizedClient]);
            }

            if (smiVersion?.name && smiVersion?.code) {
                return `${smiVersion.name}.${smiVersion.code}`;
            }

            return defaultText;
        };

        const clientPhoneNumber = getClientPhoneNumber();
        const normalizedType = type.toLowerCase();

        return {
            date: parseDate(createdAt, 'DD.MM.YYYY, HH:mm:ss.SSS') + getUtcOffsetText(dayjs()),
            description: RendererMap[normalizedType]
                ? React.createElement(RendererMap[normalizedType], {
                      item,
                  })
                : translate('action-log-item.unknown-action'),
            user: {
                name: user?.name,
                email: user?.email,
                clientPhoneNumber,
            },
            appVersion: getClientText(),
            key: `${createdAt}-${idx}`,
        };
    });

    return (
        <TableContainer fullSpace={!isStandaloneApp}>
            <DataTable small headers={headers}>
                {rows.map(({ date, description, user, appVersion, key }) => {
                    return (
                        <ErrorBoundary key={key}>
                            <DataTableRow>
                                <th scope="row">{date}</th>
                                <td>{description}</td>
                                <td>
                                    {user?.name || ''} {user?.email || ''}
                                    {user.clientPhoneNumber && (
                                        <div>
                                            <Icon InlineIcon={IconPhoneSolid} size={4} />
                                            {user.clientPhoneNumber}
                                        </div>
                                    )}
                                </td>
                                <td>{appVersion}</td>
                            </DataTableRow>
                        </ErrorBoundary>
                    );
                })}
            </DataTable>
        </TableContainer>
    );
};

export default LogsTable;
