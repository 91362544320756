import React, { useState, useEffect, useContext } from 'react';
import { DEFAULT_FILTERS } from './filters';
import { TranslateContext } from '../../../../shared/system/translate';
import { Accordion, Button, Checkbox, Fieldset, Heading, Legend } from '@flixbus/honeycomb-react';

const Filters = ({ onFilterChange, logs }) => {
    const { translate } = useContext(TranslateContext);
    const [activeFilters, setActiveFilters] = useState({});
    const [filtersCount, setFiltersCount] = useState({});

    useEffect(() => {
        setFiltersCount(
            logs.reduce((acc, curr) => {
                const filterName = curr.type.toLowerCase();

                let rootProperty = null;

                for (const key in DEFAULT_FILTERS) {
                    if (DEFAULT_FILTERS[key].includes(filterName)) {
                        rootProperty = key;
                        break;
                    }
                }

                acc[filterName] = (acc[filterName] || 0) + 1;
                if (rootProperty) {
                    acc[rootProperty] = (acc[rootProperty] || 0) + 1;
                }

                return acc;
            }, {})
        );
    }, [logs]);

    const getLabel = (translate, filter) => {
        return (
            <span>
                {translate(filter)}{' '}
                {filtersCount[filter] && <strong>({filtersCount[filter]})</strong>}
            </span>
        );
    };

    const toggleFilter = (category, filter) => {
        setActiveFilters((prev) => {
            const currentFilters = { ...prev };

            const toggleIndividualFilter = () => {
                if (currentFilters[category]?.includes(filter)) {
                    return currentFilters[category].filter((f) => f !== filter);
                } else {
                    return [...(currentFilters[category] || []), filter];
                }
            };

            const toggleCategory = () => {
                return currentFilters[category]?.length === DEFAULT_FILTERS[category].length
                    ? []
                    : [...DEFAULT_FILTERS[category]];
            };

            currentFilters[category] = filter ? toggleIndividualFilter() : toggleCategory();

            return currentFilters;
        });
    };

    const resetFilters = () => {
        setActiveFilters({});
    };

    useEffect(() => {
        onFilterChange(activeFilters);
    }, [activeFilters, onFilterChange]);

    return (
        <>
            <Heading size={3} sectionHeader>
                {translate('logs-filters.heading')}
            </Heading>
            <Heading size={4} sectionHeader>
                {translate('logs-filters.action-types')}
            </Heading>
            {Object.keys(DEFAULT_FILTERS).map((category) => (
                <Accordion
                    open={false}
                    title={
                        <Legend style={{ fontWeight: 'bold' }}>
                            <Checkbox
                                value=""
                                id={`category_${category}`}
                                label={getLabel(translate, category)}
                                checked={
                                    activeFilters[category]?.length ===
                                    DEFAULT_FILTERS[category].length
                                }
                                onChange={() => toggleFilter(category, null)}
                                small
                            />
                        </Legend>
                    }
                    key={category}
                >
                    <Fieldset>
                        {DEFAULT_FILTERS[category].map((filter) => (
                            <Checkbox
                                value=""
                                id={filter}
                                key={filter}
                                label={getLabel(translate, filter)}
                                checked={activeFilters[category]?.includes(filter) || false}
                                onChange={() => toggleFilter(category, filter)}
                                small
                            />
                        ))}
                    </Fieldset>
                </Accordion>
            ))}
            <Button onClick={resetFilters} appearance="secondary" style={{ marginTop: '20px' }}>
                {translate('clear-filters')}
            </Button>
        </>
    );
};

export default Filters;
