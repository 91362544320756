import React, { useContext } from 'react';
import { TranslateContext } from '../../../../shared/system/translate';
import RideTag from '../../../../shared/components/RideTags/components/RideTag';

const RideTagAssignmentV2 = ({ item }) => {
    const { translate } = useContext(TranslateContext);
    const { tags } = item.details;

    return tags.length ? (
        <span>
            {translate('current_ride_tags')}:{' '}
            {tags.map(({ tagId, tagName, tagColor }) => (
                <>
                    <RideTag key={tagId} name={tagName} color={tagColor} small />{' '}
                </>
            ))}
        </span>
    ) : (
        <span>{translate('ride_tags_removed')}</span>
    );
};

export default RideTagAssignmentV2;
