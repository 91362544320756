import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../../shared/system/translate';
import RideTag from '../../../../shared/components/RideTags/components/RideTag';

// TODO: remove in future. Exists only for backward-compatability
export default function RideTagAssignment({ item }) {
    const { translate } = useContext(TranslateContext);
    const actionText =
        item.type === 'TAG_REMOVED' ? translate('ride-tag.removed') : translate('ride-tag.added');

    return (
        <div>
            {translate('ride-tag.tag')}&nbsp;
            <RideTag
                key={item.details.tag.tagId}
                name={item.details.tag.tagName}
                color={item.details.tag.tagColor}
                small
            />
            &nbsp;{actionText}
        </div>
    );
}

RideTagAssignment.propTypes = {
    item: PropTypes.object.isRequired,
};
