import styled from "styled-components";

export const Cell = styled.div`
  font-size: 16px;
  color: #353535;
`;

export const TableContainer = styled(({ fullSpace, ...rest }) => <div {...rest} />)`
  width: 100%;
  margin: 0 auto;
`;