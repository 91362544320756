import { useReducer, useCallback, useEffect } from 'react';
import axios from 'axios';

const initState = {
    data: null,
    pending: false,
    error: false,
};

const reducer = (state, action) => {
    const { type, data } = action;
    switch (type) {
        case 'data':
            return {
                ...state,
                data,
                pending: false,
                error: false,
            };
        case 'error':
            return { ...state, error: data, pending: false };
        case 'pending':
            return { ...state, pending: true, error: false };
        case 'reset':
            return { ...initState };
        default:
            return { ...state };
    }
};

const BASE_URL = process.env.NODE_ENV === 'development'
    ? '/api'
    : 'https://action-logger.bruteforce-prod.flixtech.io/api';

/**
 * Hook to use Api request with
 * Note: this hook already handle request cancellation with effect
 * @func useApi
 * @param {string} method - literal methods `post`, `get` etc.
 * @param {string|function} url - endpoint, related to apiUrl in client options, to request
 * @param {object} [customClient] - data to send in post body or get params
 * @returns {array} - [response object, requestCaller, resetResponse]
 */
export default function useApi(method, url, customClient) {
    const [response, dispatch] = useReducer(reducer, initState);
    const client = customClient || axios;
    const { source } = client.CancelToken;

    useEffect(
        () => () => {
            if (typeof source.cancel === 'function') {
                source.cancel();
            }
        },
        [source]
    );

    /**
     * memoized function that will call request
     * @name requestCaller
     * @param {...object} args - arguments that will be applied to the request call
     * data, params, options, etc...
     */
    const request = useCallback(
        ({ data, url, options = {} }) => {
            const config = {
                method,
                url: url || (typeof url === 'function' ? url() : url),
                baseURL: BASE_URL,
                cancelToken: source.token,
            };

            dispatch({ type: 'pending', data: true });

            return client({ ...config, ...options, data })
                .then((res) => {
                    dispatch({ type: 'data', data: res.data });
                    return res;
                })
                .catch((e) => {
                    if (!client.isCancel(e)) {
                        dispatch({
                            type: 'error',
                            data: e,
                        });
                    }
                });
        },
        [client, method, source.token]
    );

    const reset = useCallback(() => {
        dispatch({ type: 'reset' });
    }, [dispatch]);

    return [response, request, reset];
}
