import styled from 'styled-components';

export const LogsContainer = styled(({ fullSpace, ...rest }) => <div {...rest} />)`
    padding: ${(props) => (props.fullSpace ? '0' : '0 20px')};
    margin: 0 auto 30px auto;
    min-height: 100vh;
    background-color: #f7f7f7;
`;

export const SearchFormContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
`;

export const ButtonContainer = styled.div`
    text-align: center;
    padding: 20px 0;
`;

export const IllustrationContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10vh;
`;
