export const DEFAULT_FILTERS = Object.freeze({
    ride_status: ['ride_status_changed', 'ride_cancellation'],
    passengers: ['pax_checked', 'pax_unchecked', 'pax_absent'],
    rebookings: ['rebooked'],
    ride_tags: ['tag_added', 'tag_removed', 'tag_assignment'],
    ride_concept_changed: ['ride_concept_change', 'ghost_order'],
    delays: ['delay_goodies', 'automated_delay', 'manual_delay'],
    station_status: ['station_open', 'station_closed', 'station_cancel', 'station_relocation', 'bus_arrived', 'bus_departed'],
    driver: ['driver_added', 'driver_removed'],
});
